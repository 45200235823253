<app-sidebar *ngIf="isAuthenticated"></app-sidebar>

<div id="page-content-wrapper" [class]="!isAuthenticated ? 'page-content-wrapper-na': 'page-content-wrapper'">
  <app-header></app-header>
  <main id="page-content" class="container-fluid p-0">
    <router-outlet></router-outlet>
  </main>
  
</div>

