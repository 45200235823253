import { Component } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroArrowTrendingUp,
  heroMap,
} from '@ng-icons/heroicons/outline';

const icons = { heroMap, heroArrowTrendingUp };

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterLink, RouterModule, NgIconComponent],
  providers: [provideIcons(icons)],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {}
