import { Routes } from '@angular/router';
import { AuthGuard } from './core/auth/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'start/step-1',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.routes').then((mod) => mod.auth_routes),
  },
  {
    path: 'start',
    redirectTo: 'start/step-1',
    pathMatch: 'full',
  },
  {
    path: 'start',
    loadComponent: () =>
      import('./features/start/start.component').then((m) => m.StartComponent),
    canActivate: [AuthGuard],

    children: [
      {
        path: 'step-1',
        loadComponent: () =>
          import('./features/start/step-1/step-1.component').then(
            (m) => m.Step1Component
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'step-2',
        loadComponent: () =>
          import('./features/start/step-2/step-2.component').then(
            (m) => m.Step2Component
          ),
        canActivateChild: [AuthGuard],
      },
    ],
  },
  {
    path: 'goals',
    loadComponent: () =>
      import('./features/goals/goals.component').then(
        (m) => m.OverviewComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'core-values-test',
    loadComponent: () =>
      import(
        './features/core-values/core-values-test/core-values-test.component'
      ).then((m) => m.CoreValuesTestComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './features/core-values/core-values-test/step-0/step-0.component'
          ).then((m) => m.Step0Component),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'step-1',
        loadComponent: () =>
          import(
            './features/core-values/core-values-test/step-1/step-1.component'
          ).then((m) => m.Step1Component),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'step-2',
        loadComponent: () =>
          import(
            './features/core-values/core-values-test/step-2/step-2.component'
          ).then((m) => m.Step2Component),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'step-3',
        loadComponent: () =>
          import(
            './features/core-values/core-values-test/step-3/step-3.component'
          ).then((m) => m.Step3Component),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'step-4',
        loadComponent: () =>
          import(
            './features/core-values/core-values-test/step-4/step-4.component'
          ).then((m) => m.Step4Component),
        canActivateChild: [AuthGuard],
      },
    ],
  },
];
