import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/layout/header/header.component';
import { SidebarComponent } from './core/layout/sidebar/sidebar.component';
import { AuthService } from './core/auth/services/auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, SidebarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'my-financial-planner-angular';
  isAuthenticated: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.refreshUser();

    this.authService.isAuthenticated.subscribe({
      next: user => {
        this.isAuthenticated = !!user;
      }
    })
  }

  refreshUser() {
    const jwt = this.authService.getJWT();
    
    if(jwt){
      this.authService.refreshUser(jwt).subscribe({
        next: _ => {},
        error: _ => {
          this.authService.logout();
        }
      })
    }else{
      this.authService.refreshUser(null).subscribe();
    }
  }
}
