

<aside class="bg-white" id="sidebar-wrapper">
  <div
    class="d-flex flex-column  text-center flex-shrink-0 px-3 h-100 mh-100 gap-4"
    style="width: 280px"
  >
    <a
      class="d-flex align-items-center link-dark text-decoration-none" style="height:4rem;"
    >
      <span class="fs-4 fw-semibold text-dark-emphasis" >Elemate</span>
    </a>

    <nav>
      <ul class="nav nav-pills flex-column mb-auto gap-1">
        <li class="nav-item">
          <a [routerLink]="['/start']" routerLinkActive="active" class="nav-link d-flex align-items-center" aria-current="page" style="gap: 0.75rem;">
            <ng-icon name="heroMap"></ng-icon>
            Start
          </a>
        </li>
        <li>
          <a [routerLink]="['/goals']" routerLinkActive="active" class="nav-link d-flex align-items-center" style="gap: 0.75rem;">
            <ng-icon name="heroArrowTrendingUp"></ng-icon>
            Goals
          </a>
        </li>
        <!-- <li>
          <a href="#" class="nav-link link-dark d-flex  align-items-center" style="gap: 0.75rem;">
            <ng-icon name="matAdsClickOutline"></ng-icon>
            Projects
          </a>
        </li>
        <li>
          <a href="#" class="nav-link link-dark d-flex align-items-center" style="gap: 0.75rem;">
            <ng-icon name="matAdsClickOutline"></ng-icon>
            Calendar
          </a>
        </li> -->
      </ul>
    
 
    </nav>
    <!-- <div class="dropdown mb-0 mt-auto py-4">
      <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
        <strong>mdo</strong>
      </a>
      <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
        <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div> -->
  </div>
  <!-- <div class="sidebar-heading border-bottom bg-light">Start Bootstrap</div>
    <div class="list-group list-group-flush">
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Dashboard</a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Shortcuts</a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Overview</a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Events</a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Profile</a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Status</a>
    </div> -->
  </aside>
